import React, {lazy} from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById('root'));

const LazyApp = lazy(() => import('./App'))

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <LazyApp/>
        </BrowserRouter>
    </Provider>
);

reportWebVitals();
